import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { loadScript } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { clientUrl } from "../../../../api/base";
import { getSettings } from "../../../../api/settingsApi";
import { setSettings } from "../../../../store/settingsSlice";
import styles from "../../../../styles/Navbar.module.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Search from "../../home/home1/Search";
import { getCategories } from "../../../../api/categoriesApi";
import "../../home/home1/dropdown.css";

function SectionHeader2() {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.value);
  const { t, i18n } = useTranslation();
  const countries = useSelector((state) => state.countries.value);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [persons, setPersons] = useState("");
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);

  const lang = i18n.language;
  const locale = useParams().lang;

  const location = useLocation();
  const { languages } = i18n;

  useEffect(() => {
    loadScript("js/sticky-header.js");
  });

  useEffect(() => {
    getCategories({ locale }).then((res) => {
      setCategories(res.data.categories);
    });
  }, [locale]);

  // console.log(categories);

  useEffect(() => {
    getSettings({ locale })
      .then((res) => {
        dispatch(setSettings(res.data.settings));
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  const handleCategoryClick = (title) => {
    console.log(`Button clicked: ${title}`);
    setActiveCategory(activeCategory === title ? null : title);
  };

  const handleToggleMenu = () => {
    setIsActive(!isActive);
  };

  // console.log(activeCategory);

  useEffect(() => {
    if (location.pathname.includes("/build")) {
      return navigate(`/${lang}`);
    }

    if (
      !location.pathname.includes(`/${languages[0]}`) &&
      !location.pathname.includes(`/${languages[1]}`)
    ) {
      return navigate(`/${lang}${location.pathname}`);
    } else {
      const newLang = location.pathname.split(`/`)[1];
      i18n.changeLanguage(newLang);
    }
  }, []);

  const handleLang = (locale) => {
    const oldLang = location.pathname.slice(0, 3);
    const newPath = location.pathname.split(oldLang)[1];
    i18n.changeLanguage(locale);
    const replaced = `${locale}${newPath}`;
    window.location.replace(clientUrl + "/" + replaced);
  };

  const handleActive = (path) => {
    if (location.pathname === path) {
      return styles.active;
    }
    return null;
  };

  const dir = lang === "en" ? "ltr" : "ltr";

  return (
    <header
      className={
        "site-header header-style-2 topbar-transparent mobile-sider-drawer-menu " +
        (isActive ? "active" : "")
      }
      dir={dir}
    >
      <div className="sticky-header main-bar-wraper">
        <div
          className="main-bar"
          style={{
            background: "#0006",
          }}
        >
          <div className="container d-flex justify-content-between align-items-center">
            {/* Logo Section */}
            <div className="logo-header">
              <NavLink to={`/${lang}`}>
                <FastImage
                  proImage={settings?.logo}
                  width={216}
                  height={50}
                  alt=""
                />
              </NavLink>
            </div>
            <div className="nav-item ms-auto">
              <form className="d-flex align-items-center mb-2">
                <div className="input-group">
                  <Search />
                </div>
              </form>
            </div>

            {/* NAV Toggle Button */}
            <button
              id="mobile-side-drawer"
              data-target=".header-nav"
              data-toggle="collapse"
              type="button"
              className="navbar-toggler collapsed"
              onClick={handleToggleMenu}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar icon-bar-first" />
              <span className="icon-bar icon-bar-two" />
              <span className="icon-bar icon-bar-three" />
            </button>

            {/* MAIN NAV */}
            <div dir={dir} className="header-nav navbar-collapse">
              <ul className="nav navbar-nav align-items-center">
                {/* Navigation Links */}
                <li className="nav-item">
                  <NavLink
                    to={`/${lang}`}
                    className={`${handleActive(`/${lang}`)}`}
                  >
                    {t("home")}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`${handleActive(`/${lang}/trips`)}`}
                    to={`/${lang}/trips`}
                  >
                    {t("trips")}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`${handleActive(`/${lang}/portfolios`)}`}
                    to={`/${lang}/portfolios`}
                  >
                    {t("portfolios")}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`${handleActive(`/${lang}/about-us`)}`}
                    to={`/${lang}/about-us`}
                  >
                    {t("about")}
                  </NavLink>
                </li>
                {categories.map((category) => (
                  <li
                    key={category.id}
                    className="nav-item dropdown position-relative"
                  >
                    <button
                      className="btn dropdown-toggle custom-color"
                      type="button"
                      aria-expanded={activeCategory === category.title}
                      onClick={() => handleCategoryClick(category.title)}
                    >
                      {category.title}
                    </button>
                    {activeCategory === category.title && (
                      <ul className="dropdown-menu position-absolute show">
                        {category.subcategories.map((subcat) => (
                          <li key={subcat.id}>
                            <Link
                              className="dropdown-item"
                              to={{
                                pathname: `/${lang}/trips/${subcat.id}`,
                              }}
                            >
                              {subcat.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
                <li className="nav-item">
                  <button
                    className="btn custom-color" // Use Bootstrap's btn-link class for alignment
                    onClick={() => handleLang(lang === "en" ? "en" : "en")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        handleLang(lang === "en" ? "en" : "en");
                      }
                    }}
                  >
                    {lang === "en" ? "En" : "En"}
                  </button>
                </li>
                {settings && settings.whatsapp && settings.whatsapp.length && (
                  <li className="nav-item">
                    <a
                      href={"https://wa.me/" + settings?.whatsapp[0]?.contact}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <WhatsAppIcon />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default SectionHeader2;
