import { Fragment, useEffect, useState } from "react";
import FastImage from "../../../globals/elements/fastimg";
import { Link, useParams } from "react-router-dom";
import { t } from "i18next";
import SectionBanner from "../../sections/common/banner/banner";
import { useDispatch, useSelector } from "react-redux";
import { serviceFetch } from "../../../store/singleServiceSlice";
import Loader from "../Loader";
import { servicesFetch } from "../../../store/servicesSlice";
import HelmetComponent from "../HelmetComponent";
import PhoneInput from "react-phone-input-2";
import { postBooking } from "../../../api/bookingApi";
import AlertToast from "../Alert";
import Error from "../Error";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getPolicy } from "../../../api/policy";

function ServiceDetailPage() {
  const dispatch = useDispatch();
  const serviceData = useSelector((state) => state.service);
  const { loaded, isLoading, value: service, error } = serviceData;

  const servicesData = useSelector((state) => state.services);
  const {
    isLoading: servicesLoading,
    loaded: servicesLoaded,
    error: servicesError,
    services,
  } = servicesData;

  const { lang, serviceId } = useParams();

  const [values, setValues] = useState({
    email: "",
    name: "",
    trip_id: serviceId,
    number_person: "",
  });
  const [phone, setPhone] = useState("");
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [err, setErr] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [policy, setPolicy] = useState([]);

  const { email, name, trip_id, number_person } = values;

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setOpen(false);
    setErr(false);
  };

  const locale = lang;

  const handleSubmit = (e) => {
    e.preventDefault();
    values["phone"] = phone;
    postBooking({ locale, values })
      .then((res) => {
        console.log(res);
        setText(
          lang === "en" ? "Booking is successful!" : "تم حجز الرحله بنجاح!"
        );
        setOpen(true);
        setDisabled(false);
        setErr(false);
        setErrors({});
        setValues({
          name: "",
          email: "",
          trip_id: serviceId,
          number_person: "",
        });
        setPhone("");
      })
      .catch((err) => {
        setErr(true);
        setErrors(err.response.data.data);
        setDisabled(false);
      });
  };

  useEffect(() => {
    dispatch(serviceFetch({ locale, serviceId }));
    dispatch(servicesFetch({ locale }));
    getPolicy({ locale }).then((res) => {
      setPolicy(res.data["policy-section"]);
    });
  }, [dispatch, serviceId]);

  console.log(policy);

  if (isLoading && servicesLoading) {
    return <Loader />;
  }

  if (error && servicesError) {
    return <h1>Fetching data error!</h1>;
  }

  const displayServices = () => {
    return (
      servicesLoaded &&
      services?.map((item, index) => {
        if (index > 2) {
          return (
            <div
            key={item.id}
            // style={{ maxWidth: "25rem" }}
            className="col-lg-4 col-md-6 col-sm-6 m-b30"
          >
            <div className="mt-box mt-product-box bdr-5 bdr-solid border border-white shadow shaodw bg-white">
              <div className="mt-thum-bx mt-img-overlay4 mt-img-effect zoom">
                <FastImage
                  proImage={item.image && item.image}
                  src={item.image}
                  alt={item.title}
                  style={{ height: 350 }}
                />
              </div>
              <div className="mt-info  text-center">
                <div className="p-a10">
                  <h4 title={item.title.substring(0, 30)} className="mt-title">
                    {" "}
                    <Link to={`/${lang}/service/detail/${item.id}`}>
                      {item.title.substring(0, 30)}
                    </Link>
                  </h4>
                  <p
                    className="fs-6"
                    style={{ height: 80 }}
                    dangerouslySetInnerHTML={{
                      __html: item.description.substring(0, 100) + "...",
                    }}
                  />
                  <div className="p-tb15">
                    <Link
                      className="site-button"
                      to={`/${lang}/service/detail/${item.id}`}
                    >
                      {t("book_now")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          );
        }
      })
    );
  };

  const currentpath = {
    crumb: t("our_trips"),
    title: t("our_trips"),
  };

  const dir = lang === "en" ? "ltr" : "ltr";

  // Settings for the slider
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Fragment>
      <HelmetComponent
        title={service?.title}
        description={service?.description}
        image={service?.image}
      />
      <SectionBanner data={currentpath} />


      {/* Service Detail */}
      <div dir={dir} className="section-full  p-t80 p-b50  ">
        <div className="container  bg-white ">
          <div className="section-content ">
          <div className="row">
  <div className="col-md-6 order-md-1 order-2">
    <div className="mt-box">
      <h2 className="m-t0">{loaded && service.title}</h2>
      <div
        className="fs-5 overflow-hidden"
        dangerouslySetInnerHTML={{
          __html: loaded && service.description,
        }}
      />
    </div>
  </div>

  <div className="col-md-6 order-md-2 order-1">
    {loaded && service.images && service.images.length > 1 && (
      <Slider {...sliderSettings}>
        {service.images.map((image, index) => (
          <div key={index}>
            <img
              src={image.url}
              alt={`${service.title} ${index + 1}`}
            />
          </div>
        ))}
      </Slider>
    )}
    {loaded && service.images && service.images.length === 1 && (
      <img
        src={service.images[0].url}
        alt={service.title}
        style={{
          width: "100%",
          height: "400px",
          objectFit: "cover",
        }}
      />
    )}
  </div>
</div>

          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
            {loaded && (
              <div className="table-responsive">
                <table className="table table-modern">
                  <thead>
                    <tr>
                      <th>{t("People")}</th>
                      <th>{t("Price")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("Single")}</td>
                      <td>{service.price} $</td>
                    </tr>
                    <tr>
                      <td>{t("Price for 2-3 people")}</td>
                      <td>{service.price_tow_three} $</td>
                    </tr>
                    <tr>
                      <td>{t("Price for 4-5 people")}</td>
                      <td>{service.price_four_five} $</td>
                    </tr>
                    <tr>
                      <td>{t("Price for more than 5 people")}</td>
                      <td>{service.price_more_five} $</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

<div
        style={{
          border: "1px solid #ddd", // Adjust the border style as needed
          padding: "20px", // Adjust padding as needed
          marginTop: "20px", // Space between breadcrumb and description
          borderRadius: "4px", // Optional: Rounded corners
          fontSize: "20px", // Adjust the font size as needed
          display: "flex",
          alignItems: "center", // Center items vertically within the container
          justifyContent: "center", // Center items horizontally within the container
        }}
      >
        <div
          style={{
            fontSize: "24px", // Adjust the title font size as needed
            fontWeight: "bold", // Make title bold
            marginRight: "20px", // Space between title and description
          }}
        >
          {policy?.title}
        </div>
        <div
          className="policy-description"
          dangerouslySetInnerHTML={{
            __html: policy?.description,
          }}
        />
      </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
            <div className=" bg-secondry">
              <div className="section-content p-lr30 p-tb30">
                <div className="section-head text-center">
                  <h2 className="text-white m-t0">{t("book")}</h2>
                </div>
                <form
                  className="cons-contact-form2 form-transparent"
                  onSubmit={handleSubmit}
                >
                  <div className="form-group">
                    <input
                      name="trip_id"
                      type="text"
                      value={serviceId}
                      onChange={handleChange}
                      className="form-control d-none"
                    />
                    <input
                      name="name"
                      type="text"
                      value={name}
                      onChange={handleChange}
                      className="form-control"
                      placeholder={t("name")}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      name="email"
                      type="text"
                      value={email}
                      onChange={handleChange}
                      className="form-control"
                      placeholder={t("email")}
                    />
                  </div>
                  <div className="form-group home-phone-page">
                    <PhoneInput
                      name="phone"
                      country={lang === "ar" ? "eg" : "us"}
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                      dir="ltr"
                      inputClass="form-control"
                      inputStyle={{
                        border: "none",
                        borderBottom: "1px solid rgb(22, 28, 42)",
                        background: "white",
                        boxShadow: "none",
                        width: "100%",
                        height: 40,
                        fontSize: 13,
                        lineHeight: 20,
                        borderRadius: "6px !important",
                        direction: lang === "en" ? "ltr" : "ltr",
                        paddingInlineStart: lang === "ar" && 50,
                      }}
                      buttonStyle={{
                        background: "white",
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      name="number_person"
                      type="number"
                      value={number_person}
                      onChange={handleChange}
                      className="form-control"
                      placeholder={t("no_persons")}
                    />
                  </div>

                  <button type="submit" className="site-button ">
                    <span className="font-weight-700 inline-block  p-lr15">
                      {t("submit")}
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="section-full p-t80 p-b50 bg-gray mt-5">
        <div className="container">
          {/* TITLE START*/}
          <div className="section-head text-center">
            <h2 data-title="Services">{t("other_services")}</h2>
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END*/}
          <div className="row d-flex justify-content-center">
            {servicesLoaded && displayServices()}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ServiceDetailPage;
