export const route = {
  INITIAL: "/:lang",
  home: {
    HOME1: "/:lang/index",
    HOME2: "/:lang/index2",
    HOME3: "/:lang/index3",
  },
  pages: {
    ABOUT: "/:lang/about-us",
    FAQ: "/:lang/faq",
    team: {
      TEAM: "/:lang/team",
      DETAIL: "/:lang/team/detail",
    },
    contact: {
      CONTACT1: "/:lang/contact-us1",
      CONTACT2: "/:lang/contact-us2",
    },
    gallery: {
      GALLERY1: "/:lang/gallery1",
      GALLERY2: "/:lang/gallery2",
    },
    ERROR404: "/:lang/error404",
    features: {
      footer: {
        FIXED: "/:lang/footer/fixed",
        LIGHT: "/:lang/footer/light",
        DARK: "/:lang/footer/dark",
      },
    },
  },
  services: {
    SERVICES: "/:lang/services",
    DETAIL: "/:lang/service/detail",
  },
  blog: {
    LIST: "/:lang/blog/list",
    LIST_SIDEBAR: "/:lang/blog/list-sidebar",
    GRID: "/:lang/blog/grid",
    GRID_SIDEBAR: "/:lang/blog/grid-sidebar",
    POST_SINGLE: "/:lang/blog/post-single",
  },
  companies: {
    INDEX: "/:lang/companies",
    COMPANY: "/:lang/company/:companyId",
    CATEGORY: "/:lang/companies/:companyId/categories/:categoryId",
  },
  shop: {
    PRODUCTS: "/:lang/trips",
    TRIPS: "/:lang/trips/:id",
    PORTFOLIOS: "/:lang/portfolios",
    DETAIL: "/:lang/shop/detail",
    CART: "/:lang/shop/cart",
    WISHLIST: "/:lang/shop/wishlist",
    CHECKOUT: "/:lang/shop/checkout",
  },
  ELEMENTS: "/:lang/elements",
  Search: "/:lang/search",
};

export function publicUrlFor(path) {
  return process.env.PUBLIC_URL + "/assets/" + path;
}

export function loadScript(src, fromPublic) {
  return new Promise(function (resolve, reject) {
    var script = document.createElement("script");

    script.src =
      fromPublic === undefined || fromPublic == null || fromPublic
        ? publicUrlFor(src)
        : src;

    script.addEventListener("load", function () {
      resolve();
    });
    script.addEventListener("error", function (e) {
      reject(e);
    });

    setTimeout(() => {
      document.body.removeChild(script);
    }, 1500);
    document.body.appendChild(script);
  });
}
