/* eslint-disable react/prop-types */
import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

const AlertToast = ({ open, message, handleClose }) => {
  const { lang } = useParams();
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;
  const dir = lang === "en" ? "ltr" : "ltr";
  return (
    <Snackbar
      dir={"ltr"}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
        severity="success"
        variant="filled"
        sx={{ width: "100%" }}
        key={vertical + horizontal}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertToast;
