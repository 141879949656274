/* eslint-disable react/prop-types */
import React from "react";
import Slider from "react-slick";
import FastImage from "../../../../globals/elements/fastimg";
import { publicUrlFor } from "../../../../globals/constants";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Padding } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SectionHome1Testimonials({ testimonials }) {
  const { lang } = useParams();
  const { t } = useTranslation();
  const dir = lang === "en" ? "ltr" : "ltr";
  const locale = lang;
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Set autoplay speed in milliseconds
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500, // Animation speed
    pauseOnHover: true, // Pause autoplay on hover
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const displayAll = () => {
    return testimonials?.map((item) => (
      <div key={item.id} className="item px-2">
        <div className="testimonial-2  clearfix bg-white "            style={{
              height: "500px", width:"500px"
            }} >
          <FastImage
            src={item.image}
            proImage={item.image}
            alt=""
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      </div>
    ));
  };

  return (
    <div
      className="section-full p-t80 p-b80 bg-center bg-full-height bg-no-repeat bg-gray"
      style={{
        backgroundImage: `url(${publicUrlFor("images/background/bg-map.png")})`,
      }}
    >
      <div className="container">
        <div className="section-content">
          <Slider {...settings}>{displayAll()}</Slider>
        </div>
      </div>
    </div>
  );
}

export default SectionHome1Testimonials;
