/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { t } from "i18next";
import FastImage from "../../../../globals/elements/fastimg";
import { useParams } from "react-router-dom";
import { getSubCategory } from "../../../../api/categoriesApi";
import PropTypes from "prop-types";

//CSS IMPORT
import "./card.css";
import SectionBanner from "../../common/banner/banner";
import Loader from "../../../components/Loader";

function SelectedTrips() {
  const { lang } = useParams();
  const { id } = useParams();
  const [trips, setTrips] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const dir = lang === "en" ? "ltr" : "ltr";
  const currentpath = {
    crumb: t("our_trips"),
    title: t("our_trips"),
  };
  useEffect(() => {
    // Call API and handle response
    const fetchTrips = async () => {
      try {
        const res = await getSubCategory({ locale: lang, categoryId: id });

        // Check if res.data and res.data.subcategory are defined
        if (res && res.data && res.data.subcategory) {
          setTrips(res.data.subcategory.trips);
        } else {
          console.error("Unexpected response structure:", res);
          setTrips([]); // Reset to empty array if structure is not as expected
        }

        setLoaded(true); // Set loaded state after handling response
      } catch (error) {
        console.error("Error fetching trips:", error);
        setTrips([]); // Handle error by resetting state
        setLoaded(true); // Set loaded state even if there is an error
      }
    };

    fetchTrips();
  }, [id]);
  console.log(trips);
  const generateTrips = () => {
    // Check if there are no trips
    if (trips.length === 0) {
      return (
        <div className="col-12 text-center fs-2 mb-5">
          <strong>No Trips Found</strong>
        </div>
      );
    }

    // Render trips if they exist
    return trips.map((trip) => (
      <div
      key={trip.id}
      // style={{ maxWidth: "25rem" }}
      className="col-lg-4 col-md-6 col-sm-6 m-b30"
    >
      <div className="mt-box mt-product-box bdr-5 bdr-solid border border-white shadow shaodw bg-white">
        <div className="mt-thum-bx mt-img-overlay4 mt-img-effect zoom">
          <FastImage
            proImage={trip.image && trip.image}
            src={trip.image}
            alt={trip.title}
            style={{ height: 350 }}
          />
        </div>
        <div className="mt-info  text-center">
          <div className="p-a10">
            <h4 title={trip.title.substring(0, 30)} className="mt-title">
              {" "}
              <Link to={`/${lang}/service/detail/${trip.id}`}>
                {trip.title.substring(0, 30)}
              </Link>
            </h4>
            <p
              className="fs-6"
              style={{ height: 80 }}
              dangerouslySetInnerHTML={{
                __html: trip.description.substring(0, 100) + "...",
              }}
            />
            <div className="p-tb15">
              <Link
                className="site-button"
                to={`/${lang}/service/detail/${trip.id}`}
              >
                {t("book_now")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    ));
  };

  return (
    <Fragment>
      <SectionBanner data={currentpath} />

      {/* Our Product */}
      {loaded ? (
        <div dir={dir} className="section-full p-t80">
          <div className="container-fluid">
            <div className="section-content">
              {/* TITLE START */}
              <div className="section-head text-center">
                <h2 data-title={"Trips"}>{t("our_trips")}</h2>
                <div className="mt-separator-outer m-b30">
                  <div className="mt-separator site-bg-primary" />
                </div>
              </div>
              {/* TITLE END */}
              <div className="row d-flex justify-content-center">
                <div className="col-sm-12 col-md-9 col-lg-9">
                  <div className="row">{generateTrips()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Fragment>
  );
}

SelectedTrips.propTypes = {
  trips: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

export default SelectedTrips;
