import React from "react";
import { t } from "i18next";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// CSS IMPORTS
import "./dropdown.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import qs from "qs"; // Import qs for query string manipulation

function Search() {
  const { lang } = useParams();
  const dir = lang === "en" ? "ltr" : "ltr";
  const navigate = useNavigate();

  const [persons, setPersons] = useState("");

  const handleSearchClick = (event) => {
    event.preventDefault(); // Prevent default form submission
    console.log("Navigating with persons:", persons); // Debugging log
    navigate(`/${lang}/search`, { state: { persons } });
  };

  const handlePersonsChange = (e) => {
    setPersons(e.target.value);
  };

  return (
    <form
      className="d-flex justify-content-center align-items-center"
      onSubmit={handleSearchClick}
    >
      <div
        className="input-group"
        style={{ maxWidth: "300px", position: "relative", top: "15px" }}
      >
        <input
          className="form-control search-input"
          type="search"
          placeholder={t("enter_title")}
          aria-label="Search"
          name="persons"
          value={persons}
          onChange={handlePersonsChange}
          style={{ paddingRight: "2.5rem" }} // Space for the button
        />
        <button
          onClick={handleSearchClick}
          className="btn search-btn"
          type="submit"
          style={{
            position: "absolute",
            right: "0.5rem",
            top: "50%",
            transform: "translate(0%, -50%)", // Center button vertically
            border: "none",
            background: "transparent",
            color: "#6c757d",
            cursor: "pointer",
            zIndex: "9999",
          }}
        >
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
    </form>
  );
}

export default Search;
