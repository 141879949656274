import axios from "axios";

export const url = "https://yousab-tech.com/EgyptTourismApi/public/api";
export let clientUrl;

if (process.env.NODE_ENV === "development") {
  clientUrl = "http://localhost:3000";
} else {
  clientUrl = "https://egypttourism.yousab-tech.com";
}

export const api = axios.create({
  baseURL: url,
});
