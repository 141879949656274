import { useState } from "react";
import Loader from "./globals/elements/loader";
import ScrollToTop from "./globals/scroll-to-top";
import RootLayout from "./layouts/root-layout";
import ToggleButton from "./app/components/ToggleButton";

function App() {

  const [isLoading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 3000);

  return (
    <>
      {isLoading && <Loader />}
      <ScrollToTop />
      <RootLayout />
      <ToggleButton />
    </>
  );
}

export default App;
