import React, { useEffect, useState } from "react";
import { loadScript, publicUrlFor } from "../../../globals/constants";
import FastImage from "../../../globals/elements/fastimg";
import CountUp from "react-countup";
import { Link, NavLink, useParams } from "react-router-dom";
import SectionHome1Testimonials from "../../sections/home/home1/section-home1-testimonials";
import { useDispatch, useSelector } from "react-redux";
import { homeFetch } from "../../../store/homeSlice";
import { postContactUs } from "../../../api/contactusApi";
import "swiper/css/navigation";
import { useTranslation } from "react-i18next";
import HomeSlider from "../../sections/home/home1/HomeSlider";
import Loader from "../Loader";
import { pagesFetch } from "../../../store/pagesSlice";
import AlertToast from "../Alert";
import PhoneInput from "react-phone-input-2";
import Gallery from "../../sections/home/home1/Gallery";
import SectionHome1Trips from "../../sections/home/home1/section-home1-trips";
import { countriesFetch } from "../../../store/countrySlice";
import FilteredTrips from "../../sections/home/home1/FilteredTrips";

import "react-phone-input-2/lib/style.css";

function Home1Page() {
  const { lang } = useParams();
  const { t } = useTranslation();
  const dir = lang === "en" ? "ltr" : "ltr";
  const locale = lang;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [values, setValues] = useState({
    email: "",
    name: "",
    message: "",
  });
  const [phone, setPhone] = useState("");

  const { email, name, message } = values;

  const { homeData, pages } = useSelector((state) => state);
  const countries = useSelector((state) => state.countries.value);

  useEffect(() => {
    dispatch(homeFetch({ locale }));
    dispatch(pagesFetch({ locale }));
    dispatch(countriesFetch({ locale }));
  }, [dispatch, locale]);

  const sliders = homeData.sliders;
  const counters = homeData.counters;
  const gallery = homeData.galleries;
  const testimonials = homeData.testimonials;

  const { aboutSection } = homeData;
  const { testimonialSection } = homeData;
  const { contactSection } = pages;
  const services = homeData.services;

  const { isLoading, loaded } = homeData;
  const errorFetch = homeData.error;

  const [filteredTripsVisible, setFilteredTripsVisible] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [persons, setPersons] = useState("");

  const searchText = "Please Choose To and From destinations";

  useEffect(() => {
    loadScript("js/custom.js");
  });

  if (isLoading) {
    return <Loader />;
  }

  if (errorFetch) {
    return <h1>Fetching data error!</h1>;
  }

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    values["phone"] = phone;
    postContactUs({ locale, values })
      .then((res) => {
        setText(lang === "en" ? "Order is successful!" : "تم الطلب بنجاح!");
        setOpen(true);
        setDisabled(false);
        setError(false);
        setErrors({});
        setValues({
          name: "",
          email: "",
          message: "",
        });
        setPhone("");
      })
      .catch((err) => {
        setError(true);
        setErrors(err.response.data.data);
        setDisabled(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setError(false);
  };

  const generateCounters = () => {
    return (
      loaded && (
        <div className="row">
          {counters?.map((counter) => (
            <div
              key={counter.id}
              className="col-md-3  m-b30 text-black mt-icon-box-wraper center bdr-1 bdr-solid bdr-gray-light bg-white p-a20 px-1"
            >
              <div className="counter font-40 font-weight-600 m-b10 site-text-primary">
                <CountUp end={Number(counter.count)} duration={10} />
              </div>
              <h4 className="m-tb0">{counter.title}</h4>
            </div>
          ))}
        </div>
      )
    );
  };

  return (
    <div dir={dir}>
      <AlertToast open={open} message={text} handleClose={handleClose} />

      {/* Slider with search */}
      <div style={{ position: "relative" }}>
        {loaded && <HomeSlider slides={sliders} />}
      </div>

      {/* {isCountryLoaded && (
            <div className="w-100  d-flex justify-content-center">
              <div class="w-50">
              <Search
                filteredTripsVisible={filteredTripsVisible}
                onSearch={handleSearch}
                countries={countries.countries}
              />
              </div>
            </div>
          )} */}

      {/* Filtered Trips */}
      {/* <div>
        <FilteredTrips persons={persons} />
      </div> */}

      {/* Shipment Solution AKA Counter */}
      <div
        className="section-full p-t50 p-b50 bg-no-repeat bg-center bg-white"
        style={{
          backgroundImage: `url(${publicUrlFor(
            "images/background/bg-map.png"
          )})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="mt-box m-b30">
                <div className="mt-thum-bx mt-img-effect mt-img-overlay2">
                  {loaded && (
                    <FastImage
                      src={aboutSection.image[0].url}
                      proImage={aboutSection.image[0].url}
                      // style={{ height: "455px" }}
                      alt="about image"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="sx-shipment-wrap">
                <div className="mt-box m-b25">
                  <h2 className="display-block m-b15 m-t0">
                    {loaded && aboutSection.title}
                  </h2>
                  <h5 className="display-block m-b15 m-t0">
                    {loaded && aboutSection.subtitle}
                  </h5>
                  <div
                    className="m-b15"
                    dangerouslySetInnerHTML={{
                      __html: loaded && aboutSection.description,
                    }}
                  />

                  <NavLink to={`/${lang}/about-us`} className="site-button">
                    {t("read_more")}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mini Trips */}
      <div className="mb-5 mt-5">
        {loaded && <SectionHome1Trips trips={services} />}
      </div>

      {/* Gallery */}
      <div className="mt-5 mb-5">
        {loaded && <Gallery gallery={gallery} showAll={false} />}
        <div className="row d-flex justify-content-center">
          <div className="col-sm-12 col-md-9 col-lg-9">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link to={`/${lang}/portfolios`} className="btn btn-primary">
                {t("see_more_photos")}
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className="mb-5 mt-5">
        {loaded && <SectionHome1Testimonials testimonials={testimonials} />}

        <div className="d-flex justify-content-center">
          <div className="d-inline px-2" style={{ textAlign: "center" }}>
            <Link
              className="m-t30 site-button"
              to={`https://www.tripadvisor.com/Attraction_Review-g294201-d25266272-Reviews-Mark_Egypt_Tours-Cairo_Cairo_Governorate.html`}
            >
              {t("reviews_us")}
            </Link>
          </div>
          <div className="d-inline px-2" style={{ textAlign: "center" }}>
            <Link
              className="m-t30 site-button"
              to={`https://www.tripadvisor.com/Attraction_Review-g294201-d25266272-Reviews-Mark_Egypt_Tours-Cairo_Cairo_Governorate.html`}
            >
              {t("show_reviews")}
            </Link>
          </div>
        </div>
      </div>

      {/* Contact Us */}
      <div
        className="section-full p-t50 p-b20 bg-no-repeat bg-center bg-white"
        style={{
          backgroundImage: `url(${publicUrlFor(
            "images/background/bg-map.png"
          )})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="sx-form-wrap m-b25">
                <h2 className="display-block m-b15 m-t0">{t("contact_us")}</h2>
                <form onSubmit={handleSubmit} className="contact-form">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          value={name}
                          onChange={handleChange}
                          className="form-control"
                          placeholder={t("name")}
                        />
                        {errors.name && (
                          <span className="text-danger">{errors.name}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          value={email}
                          onChange={handleChange}
                          className="form-control"
                          placeholder={t("email")}
                        />
                        {errors.email && (
                          <span className="text-danger">{errors.email}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          value={message}
                          onChange={handleChange}
                          className="form-control"
                          placeholder={t("message")}
                        ></textarea>
                        {errors.message && (
                          <span className="text-danger">{errors.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <PhoneInput
                          country={"us"}
                          value={phone}
                          onChange={(phone) => setPhone(phone)}
                          containerStyle={{ width: "100%" }}
                          inputStyle={{ width: "100%" }}
                        />
                        {errors.phone && (
                          <span className="text-danger">{errors.phone}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="site-button btn-block"
                        disabled={disabled}
                      >
                        {t("send")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div>{generateCounters()}</div>
        </div>
      </div>
    </div>
  );
}

export default Home1Page;
